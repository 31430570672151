// import firebase from "firebase"

// var firstConfig = {
//     apiKey: "AIzaSyAFV_tj0OribIwpPi7M-BE3BcmY9OZeJ_Q",
//     authDomain: "ctfo-music.firebaseapp.com",
//     databaseURL: "https://ctfo-music-default-rtdb.europe-west1.firebasedatabase.app",
//     projectId: "ctfo-music",
//     storageBucket: "ctfo-music.appspot.com",
//     messagingSenderId: "331144454129",
//     appId: "1:331144454129:web:a545083b4756c5b384ae50",
//     measurementId: "G-EZQZ42G0WW"
// };

// var secondConfig = {
//     apiKey: 'AIzaSyDRNYhuPiy1VnG8tvXdSiUwV6eqfzMfCFM',
//     authDomain: 'chatbout001.firebaseapp.com',
//     databaseURL: 'https://chatbout001.firebaseio.com',
//     projectId: 'chatbout001',
//     storageBucket: 'chatbout001.appspot.com',
//     messagingSenderId: '410612721827',
//     appId: '1:410612721827:web:540d6883be019799763716',
// };

// firebase.initializeApp(firstConfig);

// export const other = firebase.initializeApp(secondConfig, "other");
// export default firebase;


import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAFV_tj0OribIwpPi7M-BE3BcmY9OZeJ_Q",
    authDomain: "ctfo-music.firebaseapp.com",
    databaseURL: "https://ctfo-music-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ctfo-music",
    storageBucket: "ctfo-music.appspot.com",
    messagingSenderId: "331144454129",
    appId: "1:331144454129:web:a545083b4756c5b384ae50",
    measurementId: "G-EZQZ42G0WW"
};

const firebaseConfig2 = {
    apiKey: 'AIzaSyDRNYhuPiy1VnG8tvXdSiUwV6eqfzMfCFM',
    authDomain: 'chatbout001.firebaseapp.com',
    databaseURL: 'https://chatbout001.firebaseio.com',
    projectId: 'chatbout001',
    storageBucket: 'chatbout001.appspot.com',
    messagingSenderId: '410612721827',
    appId: '1:410612721827:web:540d6883be019799763716',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
//const provider=new GoogleAuthProvider()
const app2 = initializeApp(firebaseConfig2, "other");
const db2 = getFirestore(app2);
const auth2 = getAuth(app2);
const storage2 = getStorage(app2);

export { db, auth, storage, db2, auth2, storage2};
