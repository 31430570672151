import React, { useState, useEffect, useContext, useCallback, useReducer } from "react";
import { useLocalStorage } from 'react-use'
// import { db, auth, storage, db2, auth2, storage2 } from "../database/Firebase";
// import { collection, doc, setDoc, getDoc, getDocs } from "firebase/firestore";

import { db2 } from "../database/Firebase";
import {
  collection,
  getDocs,
  query as fsquery,
  where,
} from "firebase/firestore";


const Cart = ({ query }) => {


  const LOCAL_STORAGE_KEY = 'DJ_OBAH_SHOP'

  const INITIAL_STATE = [
    // initial state
  ]

  const reducer = (state, action) => {
   // console.log('state', state)
    //console.log('action', action)
    let update = []

    switch (action.type) {
      case 'ADD':
        let i = state.findIndex(item => item.id === action.id)

        if (i === -1) {
          state.push(action)
          update = state
        } else {
          // check the details of item before updating, it might still be unique
          if (action.size === state[i].size && action.color === state[i].color) {
            let qty = 0
            qty = parseInt(state[i].quantity)
            qty = qty + (parseInt(action.quantity))
            state[i] = action
            state[i].quantity = qty
          } else {
            state.push(action)
          }
          update = state
        }

        break;
      case 'REDUCE':
        // code block
        break;
      case 'DELETE':
        // code block
        break;
      default:
      // code block
    }


    // return updated state based on `action.type`
    return update
  }

  const usePersistReducer = () => {
    // grab saved value from `localStorage` and
    // a function to update it. if
    // no value is retrieved, use `INITIAL_STATE`
    const [savedState, saveState] = useLocalStorage(
      LOCAL_STORAGE_KEY,
      INITIAL_STATE,
    )

    // wrap `reducer` with a memoized function that
    // syncs the `newState` to `localStorage` before
    // returning `newState`. memoizing is important!
    const reducerLocalStorage = useCallback(
      (state, action) => {
        const newState = reducer(state, action)

        saveState(newState)

        return newState
      },
      [saveState],
    )

    // use wrapped reducer and the saved value from
    // `localStorage` as params to `useReducer`.
    // this will return `[state, dispatch]`
    return useReducer(reducerLocalStorage, savedState)
  }



  const [state, dispatch] = usePersistReducer()
  const [cart_quantity, setCart_quantity] = useState(0)
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {

    //Implementing the setInterval method
    const interval = setInterval(() => {
      //setCount(count + 1);

      let cartdata = JSON.parse(localStorage.getItem('DJ_OBAH_SHOP'))
      let qty = cartdata.reduce((accumulator, currentValue) => {
        return parseInt(accumulator) + parseInt(currentValue.quantity)
      }, 0);
      setCart_quantity(qty)

    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);

  }, []);

  useEffect(() => {
    const fetchData = async () => {

      //console.log('cart_quantity', cart_quantity)

    };
    fetchData();
  }, [cart_quantity]);

  return (
    <>
      <div className="cartnum">
        {cart_quantity}
      </div>
    </>
  );
};

export default Cart;
