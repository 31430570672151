import logo from './logo.svg';
import './App.scss';
import Cart from "./components/Cart";

function App() {
  return (
    <>
      <Cart />
    </>
  );
}

export default App;
